.signupPage {

    ion-toolbar {

        --border-style: none;
        --border-color: transparent;
        --padding-top: 1rem;
        --padding-bottom: 1rem;
        --padding-start: 1rem;
        --padding-end: 1rem;
    }
}

.headingText {

    h5 {

        margin-top: 0.2rem;
        color: #d3a6c7;
    }
}

.button-native {
    box-shadow: none;
    position: relative;
    left: -25px!important;
}

.CustomField_field__2yVIV ion-label p {
    color: #ea411d !important;
}