@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
.Home_homePage__1r5rH ion-header ion-img{border-bottom:3px solid #ec9523}.Home_homePage__1r5rH ion-footer{background-color:#7c3b6a;color:white}.Home_getStarted__2rcGZ{height:100%;background-color:#ffffff;background-image:radial-gradient(#b8b8b8 1px, transparent 1px),radial-gradient(#b8b8b8 1px, #fff 1px);background-size:40px 40px;background-position:0 0, 20px 20px}.Home_getStarted__2rcGZ ion-card-title{color:black !important;letter-spacing:-0.08rem;font-weight:900 !important}.Home_heading__25epe{margin-top:7rem}.Home_getStartedButton__3ssWk{font-size:1.2rem;margin-top:1rem}


* {
  font-family: montserrat, sans-serif;
  font-weight: 550;
}
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: light) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}

:root {
  --ion-background-color: #7c3b6a;
  --ion-text-color: #222;
  --ion-tab-bar-background: #7c3b6a;
  --ion-toolbar-background: #7c3b6a;
  --ion-toolbar-color: white;
}

.custom-link {
  color: rgb(236, 149, 35);
}

.custom-back {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 5px;
  font-size: 0.8rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

/* .custom-button {

  --border-radius: 5px !important;
  --background: rgb(236, 149, 35);
  --background-activated: rgb(192, 125, 38);
  --background-focused: rgb(192, 125, 38);
  font-size: 1.2rem;
  margin-top: 1rem;
} */

/* .custom-button {
  --border-radius: 5px !important;
  --background: #EE7037;
  --background-activated: rgb(192, 125, 38);
  --background-focused: rgb(192, 125, 38);
  font-size: 1.2rem;
  margin-top: 1rem;
} */
.custom-button {
  --border-radius: 20px !important;
  --background: #ee7037;
  --color: #f5f8fa !important;
  --background-activated: rgb(192, 125, 38);
  --background-focused: rgb(192, 125, 38);
  font-size: 1.2rem;
  margin-top: 1rem;
}

.hydrated p {
  color: #222 !important;
}

.hydrated {
  color: #222 !important;
}

.header-md::after {
  height: 0px !important;
}

ion-button.hydrated {
  color: #f5f8fa !important;
}

.Login_headingText__3-59J h5 {
  margin-top: 0.2rem;
  color: #222 !important;
}

.custom-back {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.ion-padding {
  margin-top: 4px;
}

.Home_homePage__1r5rH ion-footer {
  background-color: transparent !important;
}

.d-shape {
  width: 100%; /* Adjust width as needed */
  height: auto; /* Adjust height as needed */
  border-radius: 0 20px 20px 0;
  background-color: #3498db; /* Background color of the "D" */
  position: relative;
}

.d-shape:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50px; /* Adjust width to carve out the "D" shape */
  height: 100%;
  background-color: white; /* Background color of the container */
}

.Home_getStarted__2rcGZ {
  height: 100%;
  background-color: #ffffff;
  background-image: none !important;
}

.myimg {
  border-radius: 0 0 200px 200px;
}

/* .wavy {
  width: 100%; /* Adjust as needed 
  height: auto;
  display: block;
  position: relative;
  --size: 5px;
  mask: radial-gradient(var(--size) at 50% 100%, #0000 99%, #000 101%) 
        50% 100%/calc(100% / 5) 100% repeat-x;
  -webkit-mask: radial-gradient(var(--size) at 50% 100%, #0000 99%, #000 101%) 
        50% 100%/calc(100% / 5) 100% repeat-x;
} */

.wavy {
  --mask: radial-gradient(
        67.08px at 50% calc(100% - 90px),
        #000 99%,
        #0000 101%
      )
      calc(50% - 60px) 0/120px 100%,
    radial-gradient(67.08px at 50% calc(100% + 60px), #0000 99%, #000 101%) 50%
      calc(100% - 30px) / 120px 100% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

.CustomField_customInput__2gzTg {
  --background: #e8f0fe !important;
}

ion-toolbar {
  --padding-start: -1.6rem !important;
  --padding-end: 0.2rem !important;
  --padding-top: 0rem !important;
  --padding-bottom: 1.7rem !important;
  position: relative;
  top: -1rem;
}

/* .platform-ios ion-toolbar {
  --padding-start: -1.6rem !important;
  --padding-end: 0.2rem !important;
  --padding-top: 0rem !important;
  --padding-bottom: 1.7rem !important;
  position: relative;
  top: -1rem;
}
 */

.footer-md::before {
  height: 0px !important;
}

.ion-margin-top {
  margin-top: -6px !important;
}

ion-card-title {
  color: #333 !important;
  font-weight: 600 !important;
}

.Home_getStarted__2rcGZ ion-card-title {
  color: #333 !important;
  font-weight: 600 !important;
  font-size: 22px !important;
}

.moveup {
  position: relative;
  top: -4% !important;
}

.moveupimg {
  position: relative;
  top: 0% !important;
}

.sc-ion-buttons-ios-s ion-button:not(.button-round) {
  position: relative !important;
  left: -10px !important;
}

.logoimg-home {
  max-width: 100%;
  min-width: 100% !important;
  border: 0;
}

.header-ios ion-toolbar:last-of-type {
  max-height: 60px !important;
}

svg:not(:root) {
  overflow: hidden;
  position: relative;
  top: 8px !important;
}

input,
ion-select,
textarea {
  border: 0.2px solid #b8aea6 !important;
  border-radius: 20px !important;
  padding-left: 12px !important;
}

.sc-ion-buttons-md-s ion-button:not(.button-round) {
  background: darkorange!important;
  border-radius: 50%!important;
  position: relative;
  top: 9px!important
}

.sc-ion-buttons-ios-h {
  position: relative;
  top: 12px!important
}

.custom-back {
  position: relative;
  top: 4px!important;
  left: -3px!important;
}

.toolbar-container {
  background: #fff!important;
}

@media (max-width: 768px) and (min-width: 650px) {
  .header-ios ion-toolbar:last-of-type {
    max-height: 70px !important;
  }

  .moveup {
    position: relative;
    top: 15% !important;
    max-width: 70%;
  }

  .moveup-signup {
    position: relative;
    top: 5% !important;
    max-width: 70%;
  }

  .topmarginer {
    margin-top: 15% !important;
  }
}

@media (min-width: 900px) {
  .header-ios ion-toolbar:last-of-type {
    max-height: 60px !important;
  }

  .moveup {
    position: relative;
    top: 7% !important;
    max-width: 40% !important;
  }

  .moveup-signup {
    position: relative;
    top: 2% !important;
    max-width: 60% !important;
  }

  .topmarginer {
    margin-top: 5% !important;
  }

  .svgee {
    display: none !important;
  }

  .ion-page {
    overflow: auto !important;
  }

  .scroll-y {
    overflow-y: auto !important;
  }

  .logoimg-home {
    display: none !important;
  }

  .show-logo-home {
    display: block !important;
    position: absolute !important;
    left: 2% !important;
    top: 2% !important;
  }

  .Home_getStarted__2rcGZ ion-card-title {
    margin-top: 50% !important;
    max-width: 40%;
    margin: 0 auto;
  }

  ion-header ion-toolbar:first-of-type {
    padding-top: 1% !important;
    padding-left: 2% !important;
    padding-right: 2% !important;
  }
}

@media (max-width: 1000px) and (min-width: 650px) {
  .header-ios ion-toolbar:last-of-type {
    max-height: 70px !important;
  }

  .moveup {
    position: relative;
    top: 15% !important;
    max-width: 70% !important;
  }

  .moveup-signup {
    position: relative;
    top: 5% !important;
    max-width: 70% !important;
  }

  .topmarginer {
    margin-top: 20% !important;
  }

  ion-header ion-toolbar:first-of-type {
    padding-top: 1% !important;
    padding-left: 2% !important;
    padding-right: 2% !important;
  }

  .moveup-signup {
    position: relative;
    top: 5% !important;
    max-width: 70% !important;
  }

  .Home_getStarted__2rcGZ ion-card-title {
    margin-top: 40% !important;
    max-width: 40%;
    margin: 0 auto;
  }
}

@media (min-width: 501px) {
  .scroll-y {
    overflow-y: auto !important;
  }

  .logoimg-home {
    display: none !important;
  }

  .show-logo-home {
    display: block !important;
    position: absolute !important;
    left: 2% !important;
    top: 2% !important;
  }
}


@media (max-width: 1200px) {
  .toolbar-container {
    background: #fff !important;
    padding-top: 16px !important
  }

  :host(.in-toolbar:not(.ion-color):not(.in-toolbar-color)) .button-native {
    color: var(--ion-toolbar-color, var(--color));
    position: relative;
    top: 14px !important;
    background-color: darkorange;
  }
}

@media (max-width: 500px) {
  .moveup-login {
    position: relative;
    top: 2% !important;
  }

  .toolbar-container {
    padding-top: 10px !important;
  }
}

@media (max-width: 720px) and (min-width: 540px) {
  .Home_getStarted__2rcGZ ion-card-title {
    margin-top: 25% !important;
    max-width: 60%;
    margin: 0 auto;
  }

  .toolbar-container { 
    background-color: #fff !important;
  }
}

@media (min-width: 1000px) {
  .Home_getStarted__2rcGZ ion-card-title {
    margin-top: 10% !important;
    max-width: 40%;
    margin: 0 auto;
  }

  .moveup-signup {
    position: relative;
    top: 2% !important;
    max-width: 50% !important;
  }

  .button-inner {
    height: 100%;
    z-index: 1;
    background: darkorange!important;
    border-radius: 50%!important;
    padding: 3px!important;
  }
}

@media (min-width: 1300px) {
  .Home_getStarted__2rcGZ ion-card-title {
    margin-top: 10% !important;
    max-width: 40%;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
  .sc-ion-buttons-ios-h {
    position: relative;
    top: 0px!important
  }

  .moveup-signup {
    position: relative;
    top: 2% !important;
    max-width: 40% !important;
  }

  .moveup {
    position: relative;
    top: 2% !important;
    max-width: 35% !important;
  }
}


@media (prefers-color-scheme: light) {
  .md body {
    --ion-background-color: #fff !important;
    --ion-toolbar-background: none;
  }

  .ios body {
    --ion-background-color: #fff !important;
  }

  :root {
    --ion-background-color: #fff !important;
    --ion-tab-bar-background: #fff !important;
    --ion-toolbar-background: #fff !important;
  }

  .toolbar-container {
    background: #fff !important;
  }
  /*  ion-header ion-toolbar:first-of-type {
    padding-top: 3% !important;
    padding-left: 2% !important;
  } */
}



@media (prefers-color-scheme: dark) {
  .md body {
    --ion-background-color: #fff !important;
    --ion-toolbar-background: none;
  }

  .ios body {
    --ion-background-color: #fff !important;
  }

  :root {
    --ion-background-color: #fff !important;
    --ion-tab-bar-background: #fff !important;
    --ion-toolbar-background: #fff !important;
  }

  .toolbar-container {
    background: #000 !important;
  }

  ion-header ion-toolbar:first-of-type {
    padding-top: 3% !important;
    padding-left: 2% !important;
  }
}

@media (max-width: 500px) {
  .ion-justify-content-center {
    justify-content: center !important;
    position: relative;
    top: 20% !important;
  }

  .button-inner {
    height: 100%;
    z-index: 1;
    background: darkorange!important;
    border-radius: 50%!important;
    padding: 3px!important;
  }
}

:host(.in-toolbar:not(.ion-color):not(.in-toolbar-color)) .button-native {
  padding: 5px !important;
  border-radius: 50% !important;
  background: darkorange !important;
}

.alert-radio-label.sc-ion-alert-md {
  color: #222 !important
}
.Signup_signupPage__JwaHF ion-toolbar{--border-style: none;--border-color: transparent;--padding-top: 1rem;--padding-bottom: 1rem;--padding-start: 1rem;--padding-end: 1rem}.Signup_headingText__1THYc h5{margin-top:0.2rem;color:#d3a6c7}.Signup_button-native__2AFaV{box-shadow:none;position:relative;left:-25px !important}.Signup_CustomField_field__2yVIV__19XMh ion-label p{color:#ea411d !important}

.CustomField_field__2yVIV:not(:last-child){margin-bottom:1rem !important}.CustomField_field__2yVIV ion-label{padding-left:0.2rem;padding-right:0.5rem;color:#d3a6c7;display:flex;justify-content:space-between;align-content:center;align-items:center}.CustomField_field__2yVIV ion-label p{color:#ec9523}.CustomField_customInput__2gzTg{--background: #834e76;--padding-bottom: 1rem;--padding-top: 1rem;--padding-start: 1rem;--padding-end: 1rem;border-radius:10px;margin-top:0.25rem;transition:all 0.2s linear}

.Login_loginPage__3_i3W ion-toolbar{--border-style: none;--border-color: transparent;--padding-top: 1rem;--padding-bottom: 1rem;--padding-start: 1rem;--padding-end: 1rem}.Login_headingText__3-59J h5{margin-top:0.2rem;color:#d3a6c7}

